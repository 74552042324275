











































import Dropzone from '@/ui-components/dropzone/DropzoneComponent';
export default Dropzone;
