import { render, staticRenderFns } from "./DropzoneComponent.vue?vue&type=template&id=8f5c4246&scoped=true&"
import script from "./DropzoneComponent.vue?vue&type=script&lang=ts&"
export * from "./DropzoneComponent.vue?vue&type=script&lang=ts&"
import style0 from "./DropzoneComponent.less?vue&type=style&index=0&id=8f5c4246&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f5c4246",
  null
  
)

export default component.exports